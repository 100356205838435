<template>
  <div class="container">
    <div class="user-profile">
      <div class="row">
        <div class="col-12 col-md-4 user-sidebar">
          <div class="content">
            <div class="user-card">
              <div class="avatar">
                <img :src="user.avatar.url">
              </div>
              <h1>{{user.display_name || $t('UTILITY.unnamed') }}</h1>

              <div class="social-media-links">
                <a :href="user.social_facebook" v-if="user.social_facebook" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                  <span>Facebook</span>
                </a>
                <a :href="user.social_twitter" v-if="user.social_twitter" target="_blank">
                  <i class="fab fa-twitter"></i>
                  <span>Twitter</span>
                </a>
                <a :href="user.steam_url" v-if="user.steam_url" target="_blank">
                  <i class="fab fa-steam-symbol"></i>
                  <span>Steam</span>
                </a>
                <a :href="user.social_instagram" v-if="user.social_instagram" target="_blank">
                  <i class="fab fa-instagram"></i>
                  <span>Instagram</span>
                </a>
                <a :href="user.social_twitch" v-if="user.social_twitch" target="_blank">
                  <i class="fab fa-twitch"></i>
                  <span>Twitch</span>
                </a>
              </div>

              <b-btn @click="toggleFriendship" v-if="isLoggedIn">
                <span v-if="user.friendship_status == false">
                  adicionar amigo
                </span>
                <span v-else-if="user.friendship_status == 'pending'">
                  cancelar solicitacao
                </span>
                <span v-else-if="user.friendship_status == 'requested'">
                  aceitar solicitacao
                </span>
                <span v-else>
                  remover amigo
                </span>
              </b-btn>

              <b-btn :to="{ name: 'PlayerSingle', params: { id: user.steamid64 } }">
                stats
              </b-btn>

              <div class="profile-sidebar-box about" v-if="user.about">
                <h4>{{ $t('USERS.about') }}</h4>
                <p class="tagline">{{ user.about }}</p>
              </div>

              <div v-if="user.friends">
                <h5>amigos</h5>
                <div class="friend-list">
                  <div class="friend" v-for="user in user.friends" :key="user.id">
                    <router-link :to="{ name: 'UserSingle', params: { id: user.to_param }}">
                      {{user.nickname}}
                      <img :src="user.avatar.url">
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8">
          <div class="user-content">
            <div v-if="user.collections.length">
              <h3>{{ $t('USERS.collections') }}</h3>

              <div class="collection-list">
                <div v-for="collection in user.collections" :key="collection.id" class="collection-item">
                  <span class="date">{{ collection.created_at_friendly }}</span>
                  <router-link :to="{ name: 'CollectionSingle', params: { id: collection.slug } }">{{ collection.name }}</router-link>
                  <pre>{{ collection.description }}</pre>
                </div>
              </div>
            </div>

            <div v-if="user.latest_rounds_played.length">
              <h3>{{ $t('USERS.latest_rounds') }}</h3>

              <div class="collection-list">
                <div v-for="round in user.latest_rounds_played" :key="round.id" class="match_round-item">
                  <MiniRound :round="round"></MiniRound>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MiniRound from "@/components/MiniRound";
export default {
  name: "UserSingle",
  computed: {},
  data() {
    return {
      user: {
        name: "",
        nickname: "",
        display_name: "",
        were_found_us: "",
        profile_extra_a: "",
        steam_url: "",
        collections: [],
        latest_rounds_played: []
      },
    };
  },
  computed: {
  },
  methods: {
    initialize() {
      this.$http.get(`users/${this.$route.params.id}.json`).then(response => {
        this.user = response.data;
      });
    },
    toggleFriendship() {
      this.$http.patch(`users/${this.$route.params.id}/friendship.json`).then(response => {
        // console.log(response.data);
        this.user = response.data
      })
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    "$route.params.id": function(id) {
      this.initialize();
    },
  },
  components: {
    MiniRound
  }
};
</script>

<style lang="sass" scoped>
.user-profile
  // background: #dedede
  // min-height: 800px

  .user-sidebar
    .content
      padding: 20px
    .user-card
      .avatar
        width: 128px
        height: 128px
        overflow: hidden
        border-radius: 250%
        position: relative
        border: 5px solid #ccc
        margin-bottom: 20px
        img
          width: 100%
          height: 100%
      h1
        font-weight: bold
  .social-media-links
    margin-bottom: 20px
    display: flex
    a
      width: 32px
      height: 32px
      background: #aaa
      display: flex
      justify-content: center
      align-items: center
      border-radius: 4px
      transition: all .3s
      color: #333

      &:not(:first-child)
        margin-left: 5px

      &:hover
        text-decoration: none
        background: #333
        color: white

      span
        display: none

  .user-content
    padding: 20px 0
    h3
      font-weight: bold
      color: #ccc

    .collection-list
      .collection-item
        display: block
        &:not(:last-of-type)
          border-bottom: 1px solid #e1e1e1
          margin-bottom: 20px
        a
          display: block
          font-size: 20px
          font-weight: bold
          color: #111
          transition: all .3s
          &:hover
            text-decoration: none
            color: $link
        span.date
          font-size: 12px
          display: block
        pre
          display: block
          font-size: 12px

.friend-list
  .friend
    a
      display: inline-block
      width: 32px
      height: 32px
      img
        width: 32px
        height: 32px
</style>
